<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="员工名称：" prop="name">
              <el-input v-model="from.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="员工账号：" prop="loginName">
              <el-input v-model="from.loginName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>

        </el-row>
      </el-form>
    </div>
    <div class="tab">
      <div class="add">
        <el-button type="primary" @click="added" icon="el-icon-circle-plus-outline">新增</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
        <el-table-column prop="index" label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="name" label="员工名称" align="center" min-width="100">
        </el-table-column>
        <el-table-column prop="businessName" label="归属商家" align="center" min-width="200">
        </el-table-column>
        <el-table-column prop="loginName" label="员工账号" align="center" min-width="120">

        </el-table-column>
        <el-table-column prop="businessRole" label="员工岗位" align="center" min-width="120">

        </el-table-column>
        <el-table-column prop="status" label="冻结状态" align="center" min-width="80">

        </el-table-column>
        <el-table-column prop=" " label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
            <template>
              <el-popconfirm title="这是一段内容确定删除吗？" @onConfirm="handleDelte(scope.row.id)"
                @confirm="handleDelte(scope.row.id)">
                <el-button style="color: red;" type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
            <el-button type="text" @click="showDialog = true">重置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="重置密码" :visible.sync="showDialog" width="600px" height="600px">
      <div class="dia">
        <el-form label-width="120px" label-height="60px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="姓名：" prop="contactName" >
                <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="归属商家：" prop="contactName">
                <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="员工账号：" prop="contactName">
                <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="员工岗位：" prop="contactName">
                <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="新密码：" prop="contactName">
                <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <div class="diafour">
          <el-button type="danger" @click="showDialog = false">取消</el-button>
          <el-button type="primary" @click="showDialog = false">保存 </el-button>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      from: {
        businessName: '',
        businessRole: ''
      },
      queryInfo: {
        condition: {

        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      },
      total: 0,
      showDialog: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleDelte(id) {
      console.log('99')
      var that = this
      this.$http.post('/businessUser/delete', {
        id: id
      }).then(function (response) {
        console.log(response.data.data)
        if (response.data.code == 200) {
          that.$message.success('删除成功')

          that.getList()
        }
      })
    },
    added() {
      this.$router.push({ path: '/staffDetail' })
    },
    edit(id) {
      this.$router.push({
        path: '/staffDetail',
        query: {
          id: id
        }
      })
    },
    // 获取数据
    getList() {
      var that = this
      this.queryInfo.condition = this.form
      that.$http.post('/businessUser/list', that.queryInfo).then(function (response) {
        console.log(response.data.data)
        if (response.data.code == 200) {
          that.total = response.data.data.count
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data
        }
      })
    },
    search() {
      this.queryInfo.currPage = 1
      this.getList()
    },
    reset() {
      this.from = {
        name: '',
        loginName: ''
      }
      this.queryInfo.condition = {}
      this.queryInfo.currPage = 1
      this.getList()
    },
    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
  }
}

</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 0 10px 0;
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }
}
</style>
